/* eslint-disable */
<template>
  <section>
    <div class="w-full flex flex-wrap items-center justify-between mb-6 mt-3">
      <h2 class>Edit Account Information</h2>
    </div>

    <form @submit.prevent="() => {}">
      <div class="flex flex-col gap-3">
        <!-- "name" text -->
        <CustumInput
          name="name"
          :defaultData="defaultData ? defaultData.name : null"
          :defaultError="responseErrors.name || null"
          label="Name"
          placeholder="e.g. John Doe"
          :rules="{
            required: true,
            min: 3,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />
        <!-- "community_type" DDS -->
        <CustumSelect
          name="community_type"
          :defaultData="defaultData ? defaultData.community_type : null"
          :defaultError="responseErrors.community_type || null"
          label="Type"
          placeholder="select"
          :data="typesData"
          :rules="{
            required: true,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />
        <!-- "community_leader_name" -->
        <CustumInput
          name="community_name"
          :defaultData="defaultData ? defaultData.community_name : null"
          :defaultError="responseErrors.community_name || null"
          label="Employer Name"
          placeholder="e.g. University od Colorado Denver"
          :rules="{
            required: true,
            min: 3,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />

        <CustumAddress
          name="address"
          :defaultData="defaultData ? defaultData.address : null"
          :defaultError="responseErrors.address || null"
          label="Employer Address"
          labelTooltip="it is the address were your employees commute to"
          placeholder="address ..."
          :rules="{
            required: true,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />

        <CustumNumberInput
          name="community_size"
          :defaultData="defaultData ? +defaultData.community_size : null"
          :defaultError="responseErrors.community_size || null"
          label="Size (Number of Employees)"
          placeholder="Number of Employees"
          :rules="{
            required: true,
            min: 1,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
          readonly
          :disabled="true"
        />

        <CustumInput
          name="email"
          type="email"
          :defaultData="defaultData ? defaultData.email : null"
          :defaultError="responseErrors.email || null"
          label="Email Address"
          placeholder="john.doe@domain.com"
          :rules="{
            required: true,
            email: true,
            min: 3,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />
        <CustumInput
          name="phone"
          type="text"
          :defaultData="defaultData ? defaultData?.phone : null"
          :defaultError="responseErrors.phone || null"
          label="Phone Number"
          placeholder=""
          :rules="{
            required: true,
            email: false,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />
        <CustumInputPassword
          name="password"
          :defaultData="defaultData ? defaultData.password : null"
          :defaultError="responseErrors.password || null"
          label="Password"
          placeholder="At least six characters"
          :rules="{
            required: false,
            min: 8,
            password: true,
          }"
          :isTouched="isAllTouched"
          @setInput="setInput"
        />
        <!-- <Row class="ga-2" align="bottom">
          <Col :span="16">
            <CustumInput
              v-if="defaultData?.email.endsWith('.edu')"
              name="alt_email"
              :defaultData="defaultData ? defaultData.alt_email?.email : null"
              :defaultError="responseErrors.alt_email || null"
              label="Alternative email(faculty/staff)"
              placeholder="Alternative email for faculty/staff members"
              :rules="{
                required: false,
                password: false,
              }"
              :isTouched="isAllTouched"
              @setInput="setInput"
            />
          </Col>
          <Col :span="4">
            <div class="px-2">
              <Button
                type="primary"
                class="btn-gradient rounded p-0 m-0 px-2"
                block
                :loading="isLoading"
                @click="updateAltEmail"
                >Link email</Button
              >
            </div>
          </Col>
          <Col :span="4">
            <div>
              <Button
                type="danger"
                class="rounded"
                block
                :loading="isLoading"
                @click="removeAltEmail"
                >Unlink</Button
              >
            </div>
          </Col>
        </Row> -->
      </div>
      <div class="mt-6">
        <Button
          type="primary"
          class="btn-gradient rounded"
          size="large"
          block
          :loading="isLoading"
          :disabled="!isDataChanged"
          @click="handleSubmit"
          >Save</Button
        >
      </div>
    </form>
  </section>
</template>

<script>
/* eslint-disable */
import validation from '@/services/validation';
import { message, Button, List, ListItem, Row, Popconfirm, Col } from 'ant-design-vue';
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumInputPassword from '@/components/shared/form/CustumInputPassword.vue';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';

import CustumNumberInput from '@/components/shared/form/CustumNumberInput.vue';
import CustumAddress from '@/components/shared/form/CustumAddress.vue';
import authApi from '@/services/apis/auth.api';

import handleResErrors from '@/utils/handleResErrors';
import { AUTH } from '@/store/types';
import { PhX } from 'phosphor-vue';

export default {
  components: {
    CustumInput,
    CustumInputPassword,
    Button,
    CustumSelect,
    List,
    CustumNumberInput,
    Col,
    Row,
    ListItem,
    CustumAddress,
    PhX,
    Popconfirm,
  },
  data() {
    return {
      isAllTouched: false,
      isLoading: false,
      formData: {},
      isConfirmed: false,
      startAddress: null,
      newOTPEmail: null,
      newOTPPhone: null,
      typesData: [
        { id: 'Business', name: 'Business' },
        { id: 'Government', name: 'Government' },
        { id: 'School', name: 'School' },
        { id: 'Hospital', name: 'Hospital' },
        { id: 'Housing Development', name: 'Housing Development' },
      ],
      responseErrors: {},
      data: [],
      alt_email: null,
    };
  },
  mounted() {
    if (this.user?.community_txt_address) {
      this.startAddress = this.user.community_txt_address;
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user || null;
    },
    userQuery() {
      return this.$store.state.auth.userQuery || null;
    },

    defaultData() {
      if (!this.user) {
        return {};
      }
      return { ...this.user, address: this.user.community_txt_address };
    },
    isDataChanged() {
      const validetionData = validation.validateAllData(this.formData);

      const formData = { ...validetionData.data, address: this.user.community_txt_address };

      let isChanged = false;

      Object.keys(formData).forEach((key) => {
        if (formData[key] !== this.defaultData[key]) {
          isChanged = true;
        }
      });

      return isChanged;
    },
  },
  methods: {
    handleConfirm(e) {
      this.isConfirmed = e.target.checked;
    },
    setInput(vals) {
      if (vals?.alt_email) {
        this.alt_email = vals?.alt_email?.value;
      }
      this.formData = { ...this.formData, ...vals };
    },
    setInput2(vals) {
      if (vals?.email) {
        this.newOTPEmail = vals?.email?.value;
      }
      if (vals?.phone) {
        this.newOTPPhone = vals?.phone?.value;
      }
    },
    async handleSubmit() {
      this.isAllTouched = true;
      this.responseErrors = {};

      const validetionData = validation.validateAllData(this.formData);

      if (!validetionData.isValid) {
        return;
      }

      const formData = { ...validetionData.data };

      if (formData && formData.address) {
        formData.community_txt_address = formData.address.formated_address;
        formData.community_lat_cord = formData.address.lat.toString();
        formData.community_lng_cord = formData.address.lng.toString();
      }
      // else   {
      //   this.responseErrors.address = 'This address is not invalid ';
      //   return;
      // }
      if (formData.address) {
        delete formData.address;
      }

      if (!formData.community_txt_address && this.user.community_txt_address) {
        formData.community_txt_address = this.user.community_txt_address;
      }
      console.log('qqqqqqqqqqqqqqqq', formData);
      // /// STOPPPPPPPPP
      // if (!this.isLoading) {
      //   return;
      // }

      /// submit
      this.isLoading = true;

      try {
        const res = await authApi.updateUser(formData);
        if (res.status) {
          message.success('Updated successfully');
        }
        this.isLoading = false;
      } catch (error) {
        const errorMsg = error.response?.data?.message;
        if (errorMsg) {
          this.responseErrors = errorMsg;
        }

        message.error(handleResErrors(error), 10);
      }
      this.isLoading = false;
    },
    async updateAltEmail() {
      this.isLoading = true;

      try {
        const res = await authApi.updateAltEmail({ alt_email: this.alt_email });
        const data = await authApi.getUser();
        this.$store.commit(AUTH.SET_USER_DATA, data);
        message.success('Alternative email linked successfully');
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.isLoading = false;
    },
    async removeAltEmail() {
      this.isLoading = true;

      try {
        const res = await authApi.removeAltEmail({ alt_email: this.alt_email });
        const data = await authApi.getUser();
        this.$store.commit(AUTH.SET_USER_DATA, data);
        message.success('Alternative email link removed successfully');
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
      this.isLoading = false;
    },
    async addEmil() {
      try {
        const res = await authApi.addEmail(this.newOTPEmail);
        if (res) {
          const data = await authApi.getUser();
          this.$store.commit(AUTH.SET_USER_DATA, data);
          message.success('email added successfully');
          this.newOTPEmail = null;
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
    },
    async addPhone() {
      try {
        const res = await authApi.addPhoneNumber(this.newOTPPhone);
        if (res) {
          const data = await authApi.getUser();
          this.$store.commit(AUTH.SET_USER_DATA, data);
          message.success('phone added successfully');
          this.newOTPPhone = null;
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
    },
    async deletePhoneNumber(phone) {
      try {
        const res = await authApi.removePhoneNumber(phone);
        if (res) {
          const data = await authApi.getUser();
          this.$store.commit(AUTH.SET_USER_DATA, data);
          message.success('phone added successfully');
          this.newOTPPhone = null;
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
    },
    async deleteEmail(email) {
      try {
        const res = await authApi.removeEmail(email);
        if (res) {
          const data = await authApi.getUser();
          this.$store.commit(AUTH.SET_USER_DATA, data);
          message.success('phone added successfully');
          this.newOTPPhone = null;
        }
      } catch (error) {
        message.error(handleResErrors(error), 10);
      }
    },
  },
};
</script>
