/* eslint-disable */
import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const postLogin = async (body) => {
  const { data } = await apiAxios.post('/login', body);
  return data;
};
const postMethodLogin = async (body, body2) => {
  const { data } = await apiAxios.post('/login', {...body, ...body2});
  return data;
};

const postRegister = async (body) => {
  const { data } = await apiAxios.post('/register', body);
  return data;
};

const updateUser = async (body) => {
  const { data } = await apiAxios.put('/users/me', body);
  return data;
};
const updateAltEmail = async (body) => {
  const { data } = await apiAxios.post('update-alt-email', body);
  return data;
}
const removeAltEmail = async (body) => {
  const { data } = await apiAxios.delete('remove-alt-email');
  return data;
}

const getUser = async () => {
  const { data } = await apiAxios.get('/user');
  return data;
};


const getMembers = async () => {
  const { data } = await apiAxios.get('/members');
  return data;
};

const addEmail = async (email) => {
  const { data } = await apiAxios.post('/user/2FA/add-method', {value:email, type:'email'});
  return data;
};
const acceptMethod = async (otp) => {
  const { data } = await apiAxios.post('/user/2FA/accept-method', {verf_code:otp});
  return data;
};

const removeEmail = async (email) => {
  const { data } = await apiAxios.delete(`/user/email?email=${email}`);
  return data;
};
const addPhoneNumber = async (phone) => {
  const { data } = await apiAxios.post('/user/2FA/add-method', {value:phone, type:'phone'});
  return data;
};
const removePhoneNumber = async (phone) => {
  const { data } = await apiAxios.delete(`/user/phone?phone=${phone}`);
  return data;
};

const useUserQuery = () => useQuery(['user'], getUser);

export default {
  postLogin,
  postRegister,
  getUser,
  getMembers,
  updateUser,
  useUserQuery,
  acceptMethod,
  addEmail,
  removeEmail,
  addPhoneNumber,
  updateAltEmail,
  removeAltEmail,
  postMethodLogin,
  removePhoneNumber,
};
